import { useCallback, useEffect, useRef, useState } from 'react'
import Marker from '@/ui/icons/Marker'
import { CheckCircle } from 'react-feather'
import Spinner from '@/ui/components/shared/Spinner'
import { BRAND_ID } from '@/constants/constants'
import AsyncSelect from 'react-select/async'
import { resolveAddressSuggestions } from '@/graphql/query/resolveAddressSuqestions'
import { callGraphQlClient } from '@/functions/loaderGraphQl'
import { showError } from '@/functions/ui/toastify'
import { captureException } from '@sentry/nextjs'
import { useDispatch } from 'react-redux'
import axios from 'axios'

const AddressAutocompleteInput = ({ finishAction, initialValue = '' }) => {
	const dispatch = useDispatch()
	const addressSuggestTimer = useRef(null)
	const selectRef = useRef()

	const [text, setText] = useState(initialValue)
	const [userAddress, setUserAddress] = useState({
		address: null,
		resolving: false,
		isValid: false,
		gps_lat: null,
		gps_long: null
	})

	useEffect(() => {
		if (userAddress.gps_long && userAddress.gps_lat) {
			let timer1 = setTimeout(async () => {
				if (!userAddress.isValid) {
					const variables = {
						gps: {
							latitude: userAddress.gps_lat,
							longitude: userAddress.gps_long
						},

						brandId: BRAND_ID
					}

					callGraphQlClient(resolveAddressSuggestions, variables, dispatch)
						.then(res => {
							if (res && res.suggestions && res.suggestions.length !== 0) {
								const resolveLocation = res.suggestions[0]

								if (!resolveLocation.isResolved || !resolveLocation.isValidAddress) {
									showError('Prosím upřesněte adresu.')
									setUserAddress(prevState => ({
										...prevState,
										gps_long: null,
										gps_lat: null,
										isValid: false,
										resolving: false
									}))
									finishAction({
										error: true,
										message: 'Prosím upřesněte adresu',
										resolveLocation: resolveLocation,
										unFormattedAddress: userAddress.address
									})
								} else if (!resolveLocation.inDeliveryLocation) {
									showError('Bohužel adresa není v rozvozové zóně žádné z poboček.')
									setUserAddress(prevState => ({
										...prevState,
										gps_long: null,
										gps_lat: null,
										isValid: false,
										resolving: false
									}))
									finishAction({
										error: true,
										message: 'Bohužel adresa není v rozvozové zóně žádné z poboček.',
										resolveLocation: resolveLocation,
										unFormattedAddress: userAddress.address
									})
								} else {
									setUserAddress(prevState => ({
										...prevState,
										isValid: true,
										resolving: false
									}))

									finishAction({
										error: false,
										message: '',
										resolveLocation: resolveLocation,
										unFormattedAddress: userAddress.address
									})
								}
							}
						})
						.catch(err => {
							captureException(err)
							console.error(err)
						})
				}
			}, 1000)

			return () => {
				clearTimeout(timer1)
			}
		}
	}, [finishAction, userAddress])

	const handleAddressInputChange = useCallback(
		phrase =>
			new Promise(resolve => {
				if (phrase) {
					setText(phrase)
					setUserAddress(prevState => ({
						...prevState,
						resolving: true,
						isValid: false,
						gps_lat: null,
						gps_long: null
					}))
					/*    setUserAddress({
						...userAddress,
						resolving: true
					})*/
					clearTimeout(addressSuggestTimer.current)

					const seznamObject = {
						input: phrase
					}

					addressSuggestTimer.current = setTimeout(() => {
						axios
							.get(
								`https://api.mapy.cz/v1/suggest?lang=cs&limit=5&type=regional.address&apikey=${process.env.NEXT_PUBLIC_APP_MAPY_TOKEN}&query=${seznamObject.input}`
							)
							.then(res => {
								let resultData = []
								res && res.data.items.length > 0
								res.data.items.map(item => {
									resultData.push({
										value: {
											street: `${item.name} ${item.location}`,
											lat: item.position.lat,
											long: item.position.lon
										},
										label: `${item.name} ${item.location}`
									})
								})
								setUserAddress(prevState => ({
									...prevState,
									resolving: false
								}))
								resolve(resultData)
							})
							.catch(err => {
								captureException(err)
								console.error(err)
							})
					}, 1500)
				}
			}),
		[userAddress]
	)
	const onInputChange = (inputValue, { action }) => {
		if (action === 'input-change') {
			setUserAddress(prevState => ({
				...prevState,
				resolving: false,
				isValid: false,
				gps_lat: null,
				gps_long: null
			}))
			finishAction({
				error: true,
				message: 'Prosím upřesněte adresu',
				resolveLocation: null,
				unFormattedAddress: null
			})

			setText(inputValue)
		}
	}

	const onChange = addressData => {
		if (addressData) {
			setText(addressData.label)
			setUserAddress(prevState => ({
				...prevState,
				address: addressData.label,
				resolving: true,
				gps_long: addressData.value.long,
				gps_lat: addressData.value.lat
			}))
		}
	}

	const customStyles = {
		control: (base, state) => ({
			...base,
			height: '100%',
			cursor: 'text',
			boxShadow: state.isFocused && '0px 0px 2px 1px rgba(210, 210, 210,0.5)',
			borderColor: state.isFocused && 'red',
			border: state.isFocused ? '0px solid black' : '0px solid black'
		}),
		placeholder: base => ({
			...base,
			color: '#D3D2D2'
		}),
		indicatorSeparator: base => ({
			...base,
			display: 'none'
		}),
		loadingIndicator: base => ({
			...base,
			display: 'none'
		}),
		dropdownIndicator: base => ({
			...base,
			display: 'none'
		}),

		menu: base => ({
			...base,

			// override border radius to match the box
			borderRadius: 0,
			// beautify the word cut by adding a dash see https://caniuse.com/#search=hyphens for the compatibility
			hyphens: 'auto',
			// kill the gap
			marginTop: 2,
			textAlign: 'left',
			// prevent menu to scroll y
			wordWrap: 'break-word',
			zIndex: 9999
		}),
		menuPortal: base => ({ ...base, zIndex: 9999 }),

		menuList: base => ({
			...base,
			// kill the white space on first and last option
			padding: 0
		})
	}

	return (
		<div className={'bg-white flex flex-row font-normal items-center pl-4 pr-4 h-20 mt-0.5 mb-0.5 sm:pl-7 '}>
			<div className={'min-w-9 mr-5 lg:mr-12'}>
				<Marker />
			</div>
			<form
				className={'h-full w-full max-w-[75%] z-[40]'}
				onSubmit={event => {
					event.preventDefault()
				}}
			>
				<AsyncSelect
					ref={selectRef}
					styles={customStyles}
					className={'h-full text-sm xs:text-lg font-libre_franklin'}
					closeMenuOnSelect={false} //prevents menu close after select, which would also result in input blur
					blurInputOnSelect={true}
					escapeClearsValue={true}
					backspaceRemovesValue={true}
					inputValue={text}
					value={null}
					onInputChange={onInputChange}
					loadingMessage={() => 'Načítání ...'}
					noOptionsMessage={() => 'Žádné výsledky'}
					loadOptions={address => handleAddressInputChange(address)}
					onChange={onChange}
					placeholder={'Kam chcete jídlo doručit ?'}
				/>
			</form>
			<div className={`ml-5`}>
				{userAddress.isValid ? (
					<CheckCircle color={'green'} size={28} />
				) : (
					<>
						<div className={`${userAddress.resolving ? 'visible' : 'invisible'}`}>
							<Spinner color={'rgb(229, 231, 235)'} backgroundColor={'#E30A18'} />
						</div>
					</>
				)}
			</div>
		</div>
	)
}

export default AddressAutocompleteInput
